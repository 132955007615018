const imagePath = process.env.IMAGES_CDN_PATH + '/icons';
// const layoutImagePath = process.env.IMAGES_CDN_PATH + '/layout';
// const weatherImagePath = process.env.IMAGES_CDN_PATH + '/weather';
// const experiencesImagePath = process.env.IMAGES_CDN_PATH + '/experiences';
// const animationsImagePath = process.env.IMAGES_CDN_PATH + '/animations';

export default {
    //EDIT RESERVATION........................
    pencil: imagePath + '/pencil.svg',
    plus: imagePath + '/plus.svg',
    user: imagePath + '/user.svg',
    calendar: imagePath + '/calendar.svg',
    nights: imagePath + '/nights.png',
    camera: imagePath + '/camera.png',
    checkin: imagePath + '/checkin.svg',
    check: imagePath + '/check.png',
    checkout: imagePath + '/checkout.svg',
    dateArrow: imagePath + '/dateArrow.png',
    discount: imagePath + '/discount.png',
    printer: imagePath + '/printer.png',
    history: imagePath + '/history.png',
    trash: imagePath + '/trash.png',
    pos: imagePath + '/pos.png',
    addons: imagePath + '/addons.png',
    experiences: imagePath + '/experiences.png',
    conversation: imagePath + '/conversation.png',
    refunds: imagePath + '/refunds.png',
    taxes: imagePath + '/taxes.png',

    //LANDING PAGE.............................
    cashIn: imagePath + '/cashIn.svg',
    cashOut: imagePath + '/cashOut.svg',
    bank: imagePath + '/bank.svg',
    endShift: imagePath + '/endShift.svg',

    home: imagePath + '/home.svg',
    calendar: imagePath + '/calendar.svg',
    search: imagePath + '/search.svg',
    apps: imagePath + '/apps.svg',
    lock: imagePath + '/lock.svg',
    broom: imagePath + '/broom.svg',
}