import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { some } from 'lodash'
import clsx from 'clsx'

import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import AutoComplete from '@material-ui/lab/Autocomplete'

import { searchReservation, setTextFilter, resetFilter } from '../../redux/actions/filters';
import { fetchDateFormat, DATE_FORMATS, removeEmpty } from '../../utils/utility';

const useStyles = makeStyles(theme => ({
  basicSearch: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    height: '40px',
    paddingTop: '0px',
    position: 'relative'
  },
  searchIcon: {
    paddingLeft: 20
  },
  icon: {
    height: 25,
    width: 'auto',
    verticalAlign: 'middle',
    display: 'table-cell'
  },
  autocomplete: {
    width: '100%'
  },
  textField: {
    paddingRight: 30,
    width: 'calc(100% - 30px)',
    [theme.breakpoints.down('sm')]: {
      width: '150px'
    },
    '&.MuiInput-underline': {
      borderBottom: 0
    },
    '& input': {
      fontSize: '1.7rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4rem',
      }
    }
  },
  close: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40,
    '&:hover': {
      background: '#ECECEC'
    }
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 25
  },
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px #0000001A, 0 4px 11px #0000001A',
    marginTop: '8px'
  },
  listBox: {
    padding: '4px 0px'
  },
  suggestionItems: {
    padding: '8px 12px',
    cursor: 'default',
    fontSize: '1.4rem',
    '&:hover': {
      backgroundColor: '#DEEBFF'
    }
  }
}))

const Paper = props => {
  const classes = useStyles()
  const { children } = props
  return some(children, child => child) ? <div className={classes.paper}>{children}</div> : null
}

const Listbox = React.forwardRef((props, ref) => {
  const classes = useStyles()
  return (
    <ul id={props.id} className={clsx(classes.listBox)} role={props.role} onMouseDown={props.onMouseDown}>
      {props.children.map((child, index) => (
        <li
          key={index}
          className={clsx(child.props.className, classes.suggestionItems)}
          data-option-index={index}
          onClick={child.props.onClick}
          onMouseOver={child.props.onMouseOver}
          onTouchStart={child.props.onTouchStart}
        >
          {child.props.children}
        </li>
      ))}
    </ul>
  )
})

const SearchBar = (props) => {
  const classes = useStyles();
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [search, setSearch] = React.useState({
    roomType: props.filters.roomType,
    status: props.filters.status,
    date: props.filters.arrivalDate
  })

  const closeSearch = () => {
    props.dispatch(resetFilter());
  }

  const handleFieldChange = name => event => {
    let params = {};
    let searchParams = search;
    let value = event && (event.value || event.value === "") ? event.value : event || null;
    searchParams[name] = value;
    if (!value) {
      searchParams = removeEmpty(searchParams);
    }
    setSearch({ searchParams });
    params = { ...searchParams };
    if (params.date) params.date = fetchDateFormat(params.date, DATE_FORMATS.DEFAULT_DATE);
    if (Object.keys(params).length)
      props.dispatch(searchReservation(params));
    else
      props.dispatch(resetFilter());
  }

  const handleSetKeyword = (value) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => handleFieldChange("keyword")({ value }), 100));
  }

  return (
    <div className={classes.basicSearch}>
      <AutoComplete
        autoComplete
        options={props.filters.suggestions || []}
        freeSolo
        inputValue={props.filters.text}
        filterOptions={option => option}
        className={classes.autocomplete}
        disableClearable
        onInputChange={(e) => {
          if (!e) return
          const value = e.target.value || e.target.textContent
          handleSetKeyword(value)
          props.dispatch(setTextFilter(value))
        }}
        renderInput={params => (
          <TextField
            {...params}
            id='standard-basic'
            className={classes.textField}
            margin='none'
            type='text'
            autoFocus
            placeholder='Search reservations...'
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />)}
        PaperComponent={Paper}
        ListboxComponent={Listbox}
      />
      <div className={classes.close}>
        <CloseIcon className={classes.closeIcon} onClick={closeSearch} />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters
  }
}

export default connect(mapStateToProps)(SearchBar)
