
import http from "../utils/http";
import { normalize } from "normalizr";
import { reservationAllSchema } from "../schema/reservation";


//----------------------------------------------------------------
// SEARCH UI TOGGLE
//----------------------------------------------------------------
export const toggleSearchStatus = () => ({
  type: 'TOGGLE_SEARCH_ACTIVE',
  status: true
});

// Set Text Filter
export const setTextFilter = (text = '') => ({
    type: 'SET_TEXT_FILTER',
    text
})

// Sort by Date
export const setSortBy= (sortBy) => ({
    type: 'SET_SORT_BY',
    sortBy
});

// Set Guest Status
export const setGuestStatus= (guestStatus) => ({
    type: 'SET_GUEST_STATUS',
    guestStatus
});

// Set Room Type
export const setRoomType= (roomType) => ({
    type: 'SET_ROOM_TYPE',
    roomType
});

// Set Arrival Date
export const setArrivalDate = (arrivalDate) => ({
    type: 'SET_ARRIVAL_DATE',
    arrivalDate: arrivalDate ? arrivalDate : '' 
});

//----------------------------------------------------------------
// SEARCH RESET
//----------------------------------------------------------------
export const resetFilter = () => ({
  type: 'RESET_FILTER'
});

//----------------------------------------------------------------
// SEARCH RESERVATION
//----------------------------------------------------------------
export function searchReservation(params) {
  const ACTION_NAME = "SEARCH_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/search", {params})
      .then(successResult => {
        let normalizedData = normalize(
          successResult.data.searchResults,
          reservationAllSchema
        );
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            accommodationList: normalizedData.entities.accommodationList,
            reservation: normalizedData.entities.reservation,
            guests: normalizedData.entities.guests,
            reservationIDs: normalizedData.result,
            suggestions: successResult.data.suggestions
          }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}