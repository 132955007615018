const imagePath = process.env.IMAGES_CDN_PATH + '/placeholders';
const addonsImagePath = process.env.IMAGES_CDN_PATH + '/placeholders/addons';

export default {
    //EDIT RESERVATION........................
    privateRoom: imagePath + '/private-room.png',
    sharedRoom: imagePath + '/shared-room.png',

    //ADD ONS...............................
    lock: addonsImagePath + '/lock.jpg',
    map: addonsImagePath + '/map.jpg',
    taxi: addonsImagePath + '/taxi.jpg',
    tea: addonsImagePath + '/tea.jpg',
    towel: addonsImagePath + '/towel.jpg',

    pos: imagePath + '/pos.png',
}